import React from "react"
import {Grid} from "@material-ui/core";
import theme from '../../src/theme';

const Separator = ({title, dark}) => {
    return (
        <Grid container
              justify="center"
              className="text"
              style={{marginTop: '3em', marginBottom: '2em', background: dark ? theme.palette.primary.main : 'white', color: dark ? 'white' : '#333'}}>
            <Grid item lg={3} md={3} sm={12} xs={12} style={{textAlign: 'center'}}>
                <h2 className='text-title'>{title}</h2>
            </Grid>
        </Grid>
    );
};

export default Separator;