const SliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    fade: true
};

export default SliderSettings;
