import React from 'react'
import "./Banner.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link} from "gatsby";
import Image from "./Image";

export default function Banner({title, image, description, customStyles, link, linktext, externlink}) {
    return (
        <div className="page-banner" style={customStyles}>
            <div className="image">
                <Image image={image} />
            </div>
            <Grid container direction="column" justify="center" className="content">
                <Grid item className="title">
                    <h1>{title}</h1>
                </Grid>
                <Grid item className="description">
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </Grid>
                <Grid item style={{textAlign: 'center'}}>
                    {link && !externlink && <Link to={link}>
                        <Button variant="contained" key={<slide className="title" />} size="small" color="primary">
                            {linktext}
                        </Button>
                    </Link>}

                    {link && externlink && <a href={link}>
                        <Button variant="contained" key={<slide className="title" />} size="small" color="primary">
                            {linktext}
                        </Button>
                    </a>}
                </Grid>

            </Grid>
        </div>
    )
};