import React from "react"

import "./css/IconText.css";
import Image from "./Image";

const IconText = ({icon, alt, title, description, color}) => {
    return (
        <div className="icon-text" style={{color}}>
            <Image image={icon} />
            <div className='icon-title'>{title}</div>
            {description && <div className='icon-description' dangerouslySetInnerHTML={{__html: description}} />}
        </div>
    );
};

export default IconText;