import React from 'react';
import Img from "gatsby-image"

const Image = function ({image}) {
    if(image && image.localFile) {
        return (
            <Img alt={image.alt_text} title={image.title} description={image.description} fluid={image.localFile.childImageSharp.fluid} />
        );
    }
    return <div>No image</div>;
};

export default Image;
