import React from "react"
import Grid from "@material-ui/core/Grid";
import Image from "./Image";
import './ImageTextBox.css';

const Gallery = ({ images, ...props }) => {
    return (
        <Grid container spacing={4} justify="center" className="grid-default" {...props}>
            {images.map((item, index) => {
                return (
                    <Grid key={"gallery_" + index} item lg={4} md={4} sm={12} xs={12}>
                        <Image image={item} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default Gallery