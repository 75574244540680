import React from "react"
import Separator from "./Separator";
import {Grid} from "@material-ui/core";
import Image from "./Image";

export const Contact = ({contact}) => {
    return (
        <div>
            <Separator dark title="Kontakt" />
            {contact && <Grid container spacing={10} justify="center" className="grid-default" style={{textAlign: 'center'}}>
                {contact.opening_hours && <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div><h2>{contact.opening_hours.title}</h2></div>
                    <div dangerouslySetInnerHTML={{__html: contact.opening_hours.description}} />
                </Grid>}
                {contact.contact_details && <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div>
                        <Image image={contact.contact_details.image} />
                    </div>
                    <div>
                        <h2 className='text-title'>{contact.contact_details.title}</h2>
                    </div>
                    <div className='text-description' dangerouslySetInnerHTML={{__html: contact.contact_details.description}} />
                </Grid>}
                {contact.address && <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div><h2 className='text-title'>{contact.address.title}</h2></div>
                    <div className='text-description' dangerouslySetInnerHTML={{__html: contact.address.description}} />
                </Grid>}
            </Grid>}
        </div>
    )
};