import React from 'react';
import {Grid} from "@material-ui/core";

export default function TitleDescriptionBlock({title, description, ...props}) {
    return (
        <Grid container justify="center" className="text" {...props}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <h1 className='text-title'>{title}</h1>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <div className='text-description' dangerouslySetInnerHTML={{__html: description}} />
            </Grid>
        </Grid>
    );
}
