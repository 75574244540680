import React from 'react';
import {Grid, List} from "@material-ui/core";
import IconText from "./IconText";
import theme from "../theme";

export default function IconTexts({items, color, ...props}) {
    return (
        <Grid container spacing={10} justify="center" className="grid-default" {...props}>
            {items.map((icontext, index) => {
                return (
                    <Grid key={icontext.default.title + + "_" + index}  item lg={3} md={4} sm={12} xs={12}>
                        <IconText
                            color={color}
                            title={icontext.default.title}
                            description={icontext.default.description}
                            icon={icontext.default.image}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}
